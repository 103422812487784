p{
    color: grey;
}

.mobile-footer{
    display: none;
}

.Footer{
    border-top: solid 1px white;
    background-color: black;
    padding-top: 25px;
    display: flex;
    justify-content: space-around;
}

.footer-left{
    color: grey;
}

.footer-copyright-bottom{
    font-size: 20px;
}

.footer-right{
    display:flex;
    justify-content: space-between;
    width: 50%;
    margin-right: 100px;
}

.footer-right1{
    width: 20%;
}

.footer-right1-content{
    display:flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    
}
.footer-right3{
    width:40%;
}


@media screen and (max-width: 1200px){

    .Footer{
        padding-top: 0;
    }

    .footer-left{
        display: none;
    }

    .mobile-footer{
        display: block;
    }

    .footer-right{
        display: none;
    }

    .Footer{
        border-top: none;
    }

    .mobile-footer{
        border-top: solid 1px white;
        width: 100%;
        margin: 0 50px;
        text-align: center;
    }

    .mobile-footer-top{
        display: flex;
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
    }

    .mobile-footer-left{
        width: 30%;
        margin-left: auto;
    }

    .mobile-footer-right{
        width: 30%;
        margin-right: auto;
    }

    .mobile-footer-title{
        margin: 30px;
        font-family: 'Playfair Display';
    }

    .mobile-footer-text{
        font-size: 20px;
    }

    .mobile-footer-bottom{
        border-top: solid 1px white;
        padding: 30px 50px 25px 50px;
    }
}

.rowwidth {
    width: 50%;
}
.linkdeco {
    text-decoration: none;
    color: gray;
}