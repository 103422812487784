.slick-list, .slick-slider {
    padding: 0px 0px 0px 0px !important;
  }
  .ownGallerySlider > .slick-list > .slick-track > .slick-slide > div {
    padding-bottom: 1vmax !important;
  }
  
  .slick-dots li button:before {
    color: rgb(255, 255, 255);
   }
   .slick-dots li.slick-active button:before {
    color: rgb(255, 255, 255);
   }   

.Gallery{
    background-color: black;
    padding-top: 100px;
}

.gallery-title{
    text-align: right;
    padding: 0 150px;
    font-size: 70px;
    font-family: 'Antic Didone';
}



.image__overlay1{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    font-family: 'Antic Didone';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 1;
}

.image__overlay1 > * {
    transform: translateY(20px);
    transition: transform 0.25s;
}

.image__overlay1:hover{
    opacity: 0;
}

.image__overlay1:hover > * {
    transform: translateY(0);
}

.image1{
    box-shadow: 1px 1px 30px #993300;
    position: relative; 
}
.image1 >img{
    width:100%;
}
/* rgb(103 58 183) */



@media screen and (max-width: 1200px) {
    .gallery-title{
        font-size: 40px;
        padding: 50px;
    }
    .Gallery{
        padding-top: 0;
    }
}

@media screen and (max-width: 991px){
    .gallery-title{
        font-size:30px;
        padding: 25px;
    }
}