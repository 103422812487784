.Home {
    background-image: url('../../util/Group\ 149.jpg');
    width: 100%;
    height: 877px;
}

.home-icons{
    position: fixed;
    top: 40%;
    right: 0;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    height: 200px;
}

.social-link{
    margin: 20px 0 0 0;
}

.social-btn{
    border: 1px solid white !important;
    border-radius: 50%;
    padding: 10px;  
    box-shadow: inset 0 0 15px #000;
}

.layer{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
}

.homeTitle{
    color: white;
    font-family: 'Abril Fatface';
    font-size: 144px;
    margin-left: 75px;
    letter-spacing: 70px;
    padding-right: -70px;
    padding-top: 350px;
    user-select: none;
}

.flex{
    width: 100%;
    height: 100%;
    color: #ffffff;
    display: flex;
    justify-content: center;
    padding-bottom: 350px;
}

.social-text{
    color: white;
}

@media screen and (max-width: 991px) {
    .Home{
        height: 450px;
        width: 100%;
        background-size: cover;
    }
    .homeTitle{
        font-size: 50px;
        letter-spacing: 25px;
        font-weight: bold;
        padding-right: -30px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 250px;
    }
  }