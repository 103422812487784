.Menu{
    background-color: black;
    padding: 1px 150px;
    font-family: 'Antic Didone';
    padding-top: 250px;
}

.mobile-menus-photos{
    display: none;
}

.menu-title{
    font-size: 70px;
}

.menus{
    display: flex;
    justify-content: center;
    font-size: 28px;
    width: 100%;
}

.firstMenu{
    padding-right: 30px;
    border-right: solid 1px white;
    margin-top: 30px 0 !important;
    background-color: none;
}

.menu{
    padding: 0 30px;
    border-right: solid 1px white;
    font-size: 1rem;
}
.menu.active{
    color:#ffff00;
}

.menu:last-child{
    border-right: 0px;
}

.lastMenu{
    padding-left: 30px;
}

.firstMenu:hover, .menu:hover, .lastMenu:hover, .menu-layer:hover{
    cursor: pointer;
    color: pink;
    user-select: none;
}

/* .menu-photos{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0 40px;
} */
.image{
    position: relative;
}

.image img{
    display: block;
    box-shadow: 1px 1px 30px #993300;
    /* background-color:gray; */
    position: relative; 
    margin:20px auto;
    
}

.menu-photos{
    width: 100%;
    
}

.image__overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    font-family: 'Antic Didone';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

a{
    color:white;
    text-decoration: none;
}

a:hover{
    color: pink;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
}

.image__overlay:hover{
    opacity: 1;
}

.image__title{
    font-size: 2em;
    font-weight: bold;
    text-align: center;
}

.image__overlay:hover > * {
    transform: translateY(0);
}

.cost{
    font-size: 2em;
    font-weight: bold;
    color: white;
}

@media screen and (max-width: 1500px){
    .Menu{
        padding-top: 100px;
    }
}
@media screen and (max-width: 1200px) {

    .mobile-menus{
        display: block;
    }

    .Menu{
        padding: 100px 50px;
    }

    .mobile-menus-top{
        display: flex;
        width: 100%;
        padding: 0 auto;
        justify-content: space-evenly;
    }

   
    .menu-layer{
        background-color: #19242C;
        display: flex;
        width: 30%;
        height: 75px;
        text-align: center;
        justify-content: center;
        margin: 10px;
    }
    .menu-layer.active {
        background: #364d5d;
    }

    .menu-title{
        font-size: 40px;
    }

    .image__title{
        font-size: 1.5em;
        font-weight: bold;
    }

    .cost{
        font-size: 1.5em;
    }

    .mobile-menus{
        margin: 25px;
    }
}

@media screen and (max-width: 991px){
    .menus{
        flex-wrap:wrap;
    }
    .menu{
        display: flex;
        align-items: center;
        justify-content: center;
        margin:1%;
        background: #4f83a7aa;
        border:0px;
        padding:0.5rem 0rem;
        text-align: center;
        line-height: 1;
        height: 3rem;
        flex:0 0 30%;
        max-width:30%;
    }
    .menu.active{
        background:#4f83a7;
    }

    
    .mobile-menus-photos{
        display: block;
    }

    .Menu{
        padding: 50px 0 1px 0;
    }

    .menu-title{
        padding-left: 35px;
        font-size: 30px;
    }

  

    .mobile-menus-row{
        display: flex;
        width: 100%;
        flex-wrap:wrap;
        justify-content: space-around;
    }

    .mobile-menu{
        text-align: center;
        width: 38%;
        margin-bottom: 10px;
    }

    .mobile-menu-photo{
        width: 100%;
        box-shadow: 1px 1px 30px #993300;
    }

    .mobile-menu-description{
        color: white;
        font-family: 'Antic Didone';
        font-size: 20px;
        margin: 0;
    }

    .mobile-menus{
        display: block;
    }
}

@media screen and (max-width: 650px){
    .Menu{
        padding-top: 50px;
    }
}