html, body {
    color: white;
}

.App {
    width: 100%;
}

.home-icons {
    top: 0;
}

@media screen and (max-width: 1030px) {
    .title{
        font-size: 30px;
    }
}