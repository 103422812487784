.Contact{
    background-color: black;
    padding: 5px 100px 30px 150px;
}

.contact-mobile-right{
    display: none;
}

.contact-title{
    font-size: 70px;
    font-family: 'Antic Didone';
}

.contact-content{
    display: flex;
    font-family: 'Anaheim';
}

.contact-right{
    margin: 50px 0 30px 100px;
}

.contact-text{
    font-size: 30px;
    margin: 5px 0;
}

.contact-text-middle{
    display: flex;
}

#tabooLogo2{
    margin-bottom: 50px;
}

.contact-bottom{
    display:flex;
    justify-content: space-between;
    margin-top: 180px;
}

.contact-bottom-right{
    display:flex;
    width: 60%;
    margin-top: 20px;
    margin-right: 150px;
    justify-content: space-around;
}

.contact-bottom-right-button{
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.contact-bottom-right-button:hover{
    color: pink;
}

#tabooLogo3{
    margin-left: 20px;
    width: 80px;
}

#contact-map{
    width: 40%;
}

@media screen and (max-width: 1350px){
    .contact-text{
        font-size: 25px;
    }
}

@media screen and (max-width: 1200px){
    .contact-mobile-bottom-text{
        font-size: 20px;
    }
    .Contact{
        padding: 0 150px;
    }
    .contact-mobile-right{
        display: block;
    }
    .contact-right{
        display: none;
    }
    .contact-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #contact-map{
        width: 100%;
        height: 400px;
    }

    .contact-mobile-right{
        margin: 0;
        text-align: center;
    }

    .contact-text{
        font-size: 20px;
    }

    .contact-bottom{
        display: none;
    }

    .contact-title{
        font-size: 30px;
    }

    .contact-mobile-right-text{
        font-family: 'Anaheim';
        font-size: 20px;
        color: white;
        margin: 0;
    }

    .contact-mobile-right-texts{
        margin: 15px;
    }

    .contact-mobile-taboo{
        margin: 15px;
    }

    .contact-mobile-bottom{
        margin: 75px 0 25px 0;
    }

    .contact-mobile-bottom-title{
        margin: 25px 0;
        font-family: 'Playfair Display';
    }

    .mobile-social-link{
        margin: 20px;
    }
}

@media screen and (max-width: 991px){
    .Contact{
        padding: 0 50px;
    }
}


@media screen and (max-width: 650px){
    .Contact{
        padding: 0 25px;
    }
}