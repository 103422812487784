.about {
    background-color: black;
    width: 100%;
    font-family: 'Antic Didone';
    padding-top: 70px;
}

#mobile-about-image1{
    display: none;
}

.about-top{
    width: 100%;
    display: flex;
}

.about-top-description{
    font-family: 'Anaheim';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    align-items: center;
    letter-spacing: 3px;
    color: gray;
}

.about-top-left{
    width: 50%;
    padding: 100px;
}

.about-top-right{
    width: 50%;
    height: 100%;
    padding-top: 50px;
}

.about-right{
    display:flex;
    flex-direction: column;
}
.about-middle{
    display: flex;
    justify-content: space-around;
    padding: 10px;
}

.about-middle-left{
    width: 50%;
}

.about-middle-right{
    width: 50%;
}

.about-middle-description{
    font-family: 'Anaheim';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 3px;
    color: gray;
}

.title{
    font-size: 70px;
}

#about-image1{
    width: 100%;
}

#about-image2{
    width: 45%;
    position: absolute;
    top: 1500px;
    left: -150px;
}

#about-image3{
    width: 100%;
    position: relative;
}

.about-bottom{
    display: flex;
    padding-right: 150px;
}

.about-bottom-left{
    width: 60%;
}

.about-bottom-right{
    width: 40%;
}

#mobile-about-top-description2{
    display: none;
}

#about-title{
    padding-top: 50px;
}

.mobile-about-title2{
    display: none;
}


@media screen and (max-width: 1200px) {
    .about-top-left{
        width: 60%;
        padding: 20px;
        margin: auto 0;
    }

    .about-top-right{
        width: 40%;
    }

    .about-top-description{
        color: white;
    }

    #about-top-description2{
        display: none;
    }

    #about-title, #about-title2{
        padding-bottom: 25px;
        font-size: 40px;
        padding-top: 0;
    }

    .title{
        font-size: 30px;
    }

    #mobile-about-top-description2{
        display: block;
        text-align: center;
        width: 100%;
        padding: 25px 200px 0 250px;
        color: white;
    }

    .about-middle-description{
        color: white;
    }

    #about-image2{
        top: 1300px;
        left: -50px;
    }
}

@media screen and (max-width: 991px) {
    #mobile-about-top-description2{
        padding: 25px 100px 0 200px;
    }

    #about-title, #about-title2{
        font-size: 30px !important;
    }
    
    .about-top-description{
        font-size: 14px;
    }

    #about-image3{
        width: 100%;
    }

    #about-image2{
        top: 820px;
        left: -50px;
    }

    .about-middle-description{
        font-size: 14px;
    }

}

@media screen and (max-width: 650px) {

    #about-image2{
        top: 870px;
        left: -50px;
    }

    .about-bottom{
        padding: 0;
    }

    .about-bottom-left{
        display:none;
    }
    
    #about-title3{
        display: none;
    }

    .mobile-about-title2{
        display: block;
        align-self: center;
        width: 60%;
        padding-left: 20px;
    }

    #about-image3{
        width: 40%;
    }

    .about-bottom-right{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #about-image1{
        padding-top: 50px;
        display: none;
    }
    #mobile-about-image1{
        display: block;
        margin: 0;
        width: 100%;
        padding-top: 0;
    }

    #mobile-about-top-description2{
        padding: 25px 50px 0 125px;
    }

    #about-title{
        padding-bottom: 30px;
    }

    .about-middle-left{
        width: 30%;
    }

    .about-middle-right{
        width: 70%;
    }
}

@media screen and (max-width: 500px) {
    #about-image2{
        top: 1000px;
        left: -50px;
    }
}